import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { authSelectors } from "@/store/slices/auth"

const RouteGuard = (props) => {
  const {
    children,
    mustAuthenticated = false,
    mustNotAuthenticated = false,
  } = props || {}

  const [showChildren, setShowChildren] = useState(false)

  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn)
  
  const navigate = useNavigate()
  const prevLocation = useLocation()
  const searchParams = new URLSearchParams(window.location.search)
  const queryString = searchParams.get('redirectTo')
  const tabType = searchParams.get("type");

  useEffect(() => {
    setShowChildren(false)

    if (mustAuthenticated && !isLoggedIn) {
      if (!!tabType) {
        navigate(`/sign-in?redirectTo=${prevLocation.pathname}?type=${tabType}`);
      } else {
        navigate(`/sign-in?redirectTo=${prevLocation.pathname}`);
      }
      return
      
    } else if (mustNotAuthenticated && isLoggedIn) {
      navigate(!!queryString? queryString : "/")
      return
    }

    setShowChildren(true)
  }, [isLoggedIn])

  return (
    <>
      {!!showChildren && children}
    </>
  )
}

export default RouteGuard