import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import SvgIcon from "@/components/svg-icon"
import { Divider, Tooltip, Row, Drawer } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from 'react-router-dom';
import { useClickOutsideAlerter, useCustomMediaQuery } from "@/hooks"
import { allRoutes } from "@/router"
import LogoTextImage from "@/assets/images/logo-text.png"
import { useTranslation } from "react-i18next";

const menuTooltipStyle = {
  background: "white",
  color: "var(--default-font-color)",
  borderRadius: "2rem",
  padding: "0.25rem 1rem",
  fontSize: "var(--font-size-m)",
}

const BoSidebar = (props) => {
  const {
    className, setShowModalBackdrop, ...restProps
  } = props || {}

  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation();

  const { isMobile } = useCustomMediaQuery()

  const [isExpanded, setIsExpanded] = useState(false)

  const boSidebarRef = useRef(null)

  const allRoutesForSidebar = allRoutes.filter((el) => !!el.sidebarProps?.key)
  allRoutesForSidebar.sort((a, b) => a.sidebarProps?.order - b.sidebarProps?.order)

  useEffect(() => { 
    setShowModalBackdrop(isMobile && isExpanded); 
  }, [isMobile, isExpanded, setShowModalBackdrop]);

  useClickOutsideAlerter([boSidebarRef], () => {
    setIsExpanded((oldVal) => {
      if (oldVal === true) return !oldVal
      return oldVal
    })
  })
  

  const onMenuClick = (link) => {
    setIsExpanded(false)
    navigate(link)
  }

  const getMenuItem = (info) => {
    const {
      key,
      logoSrc,
      label,
      link
    } = info || {}

    const currentPath = location.pathname
    const rootLink = `/${link.split("/")[1]}`;
    const isActiveMenu = currentPath.startsWith(rootLink);

    const className = `menu-item pointer ${isActiveMenu ? 'active' : ''}`

    let logoSrcToUse = logoSrc
    if (!isActiveMenu) {
      // add -outline wording behind
      logoSrcToUse = `${logoSrc}-outline`
    }

    return (
      <Tooltip 
        key={key}
        title={t(label)}
        placement="rightTop"
        trigger={isExpanded ? "none" : "hover"}
        arrow={false}
        overlayInnerStyle={menuTooltipStyle}
      >
        <Row 
          className={className}
          justify={isExpanded ? 'start' : 'center'}
          align="middle"
          onClick={() => onMenuClick(link)}
          wrap={false}
        >
          <SvgIcon 
            width="24"
            height="24"
            src={logoSrcToUse}
            color={isActiveMenu ? 'var(--primary-color)' : null}
            className="menu-logo"
          />
        {isExpanded && ( <p className="menu-label"> {t(label)} </p> )}
        </Row>
        <Divider className="menu-divider" />
      </Tooltip>
    )
  }

  const getMenuContent = () => (
    <div id="scrollable-menu">
      <div className="menu-item-container">
        <p className="menu-title font-semi-bold opacity-50">
          {t("header_sidebar.tools")}
        </p>
        {allRoutesForSidebar
          .filter((el) => el.sidebarProps.groupingType === "TOOLS")
          .map((el) => getMenuItem(el.sidebarProps))}
      </div>
      <div className="menu-item-container">
        <p className="menu-title font-semi-bold opacity-50">
          {t("header_sidebar.others")}
        </p>
        {allRoutesForSidebar
          .filter((el) => el.sidebarProps.groupingType === "OTHERS")
          .map((el) => getMenuItem(el.sidebarProps))}
      </div>
    </div>
  );
  
  const getDesktopView = () => (
    <div
      {...restProps}
      id="sidebar"
      ref={boSidebarRef}
      className={`${className} shadow ${
        isExpanded ? "expanded" : ""
      } desktop-menu-container`}
    >
      <Row
        justify="center"
        align="middle"
        className="logo-wrapper pointer"
        onClick={() => navigate("/")}
        wrap={false}
      >
        <SvgIcon width="34" height="34" src="logo" />
        {isExpanded && (
          <img
            alt="logo-text"
            width="100"
            src={LogoTextImage}
            className="logo-text-image"
          />
        )}
      </Row>
      <div
        className="expand-icon-wrapper pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <SvgIcon
          width="24"
          height="24"
          src="expandable-icon"
          className="shadow"
        />
      </div>
      {getMenuContent()}
    </div>
  );
  
  const getMobileView = () => (
    <div className={`${className} mobile-menu-container shadow`} {...restProps}>
      <MenuOutlined
        onClick={() => setIsExpanded(true)}
        color="var(--primary-color)"
        className="mobile-menu-button"
      />
      {/* <Drawer
        title={t("header_sidebar.menu")}
        placement="left"
        onClose={() => setIsExpanded(false)}
        open={isExpanded}
        rootClassName={className}
        className="mobile-menu-drawer"
        width={278}
      > */}
        {/* {getMenuContent()}
      </Drawer> */}
    </div>
  );
  
  return isMobile && !isExpanded ? getMobileView() : getDesktopView();
  
}

const StyledComponent = styled(BoSidebar)`
  z-index: 100;
  position: fixed;
  top: 1rem;
  width: var(--sidebar-width-collapsed);
  height: calc(100vh - 1rem - 1rem);
  border-radius: 20px;

  &, * { 
    transition: all .5s ease;
  }

  &.desktop-menu-container {
    background-color: white;
  }

  .logo-text-image {
    margin-left: 1rem;
  }

  #scrollable-menu {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 1rem;
    padding-right: 1rem;

    // overflow shadow
    background:
      /* Shadow covers */
      linear-gradient(white 30%, rgba(255,255,255,0)),
      linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      
      /* Shadows */
      radial-gradient(50% 0, farthest-side, rgba(0,0,0,.1),   ),
      radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.1), rgba(0,0,0,0)) 0 100%;
    background:
      /* Shadow covers */
      linear-gradient(white 30%, rgba(255,255,255,0)),
      linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      
      /* Shadows */
      radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.1), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.1), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
    
    &::-webkit-scrollbar { display: none } // hide scrollbar
  }

  .menu-item-container {
    margin: 1rem 0;
  }

  .menu-logo {
    border-radius: 5px;
    svg {
      transition: color 0s;
    }
  }

  .menu-item {
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    &:hover,
    &.active {
      background-color: #7CE2D833;
      * {
        color: var(--color-primary) !important;
      }
    }
  }
  
  .menu-divider {
    margin: 0.2rem 0;
  }
  
  .menu-label {
    margin-bottom: 0;
    color: #7E899C;
    font-family: var(--main-font-base);
  }

  .logo-wrapper {
    margin: 1.5rem 0;
  }

  .menu-title {
    opacity: 0.4;
    text-align: center;
  }

  .expand-icon-wrapper {
    position: absolute;
    right: -12px;
    top: 3.25rem;
    margin: 0.5rem 0;
  }

  &.expanded {
    width: 278px;
    .expand-icon-wrapper {
      position: absolute;
    }
    .menu-title {
      text-align: left;
      margin-left: 0.75rem;
      margin-bottom: 0.25rem;
    }
    .menu-item,
    .logo-wrapper {
      overflow: hidden;
    }
    .menu-label {
      white-space: nowrap;
    }
    .menu-logo {
      margin-right: 1rem;  
    }
    .logo-wrapper {
      margin-bottom: 1.5rem;
    }
  }

  &.mobile-menu-container {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    background-color: white;
  }

  .mobile-menu-button {
    font-size: 18px;
    cursor: pointer;
    margin: auto;
  }

  .mobile-menu-drawer {
    .ant-drawer-body {
      position: relative;
    }
    #scrollable-menu {
      height: 74vh;
    }
  }
`


export default StyledComponent