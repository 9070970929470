import { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import dayjs from "dayjs"
import { 
  Button, Typography
} from 'antd';
import styled from "styled-components"
import { useTranslation } from "react-i18next";

dayjs.extend(require('dayjs/plugin/utc'))

const { Text } = Typography

const Component = (props) => {
  const {
    timeLeft = 180,
    onOtpComplete,
    onResendCode,

    className
  } = props || {}

  const { t } = useTranslation();

  const [ otp, setOtp ] = useState(null)
  const [ rerenderInput, setRerenderInput ] = useState(false)
  const [ rerenderResend, setRerenderResend ] = useState(false)

  useEffect(() => {
    if (otp && otp.length === 4) {
      onOtpComplete(otp)
      setOtp(null)
      setRerenderInput((val) => !val)
    }
  }, [otp])

  useEffect(() => {
    setRerenderResend((val) => !val)
  }, [timeLeft])

  const formatRemainingTime = (timeInSec) => {
    return dayjs.utc(timeInSec*1000).format("mm:ss")
  }

  return (
    <div className={className}>
      <OTPInput
        key={'input_'+rerenderInput}
        className="otp-field"
        inputClassName="otp-field-input"
        value={otp} 
        onChange={setOtp}
        autoFocus 
        OTPLength={4} 
        otpType="number" 
      />
      <ResendOTP 
        key={'resend_'+rerenderResend}
        maxTime={timeLeft || 60}
        className="otp-resend"
        renderTime={(remainingTime) => {
          return (remainingTime > 0 && (
            <Text 
              type="secondary" 
              className="otp-resend-text"
            >
              {`${t("sign_in.resend_code_in")} ${formatRemainingTime(remainingTime)}`}
            </Text>
          ))
        }}
        renderButton={({ disabled, onClick }) => {
          return (!disabled && (
            <Button 
              block
              type="primary"
              onClick={() => {
                onClick()
                onResendCode()
              }}
            >
              {t("sign_in.resend_code")}
            </Button>
          ))
        }}
      />
    </div>
  )
}

let StyledComponent = styled(Component)`
  .otp-field {
    margin: 2rem;
    justify-content: center;
  }

  .otp-field-input {
    width: 40px !important;
    height: 40px !important;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .otp-resend {
    .otp-resend-text {
      margin: auto;
    }
  }
`

export default StyledComponent