import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const index = {
  path: "company-profile",
  title: "module_title.company_profile",
  element: (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/company-profile")} 
        />
      </RouteGuard>
    </BOLayout>
  )
}

export default index