import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper";

const RouteGuardWrap = (importFn) => {
  return (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={importFn}
        />
      </RouteGuard>
    </BOLayout>
  );
};

const moduleTitle = "module_title.employee";

const index = {
  path: "employee",
  title: moduleTitle,
  element: null,
  sidebarProps: {
    key: "1-2",
    logoSrc: "employee",
    label: moduleTitle,
    link: "/employee",
    groupingType: "TOOLS",
    order: 2,
  },
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/employee/list"))
    },
    {
      path: ":uuid",
      title: "module_title.employee_profile",
      headerProps: {
        backUrl: "/employee"
      },
      element: RouteGuardWrap(() => import("@/pages/employee/details"))
    },
  ],
}

export default index