import { useState, useRef } from "react"
import { 
  Col, Row, Image, Card, Form, Input, Button, Spin, Space, Typography, App
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import styled from "styled-components";
import SvgIcon from "@/components/svg-icon"
import FormStyle from "@/styles/shared/form.js"
import { requiredRules, emailRules, passwordRules, confirmPasswordRules } from '@/constants/antd-validator-rules';
import { useAuthApi } from "@/api/auth";
import { authActions } from "@/store/slices/auth"
import { useDispatch } from 'react-redux';
import Otp from "@/components/otp"
import SignInLeftImage from "@/assets/images/sign-in-left-image.png"
import LogoTextImage from "@/assets/images/logo-text.png"
import { useTranslation } from "react-i18next";
import { useCustomMediaQuery } from "@/hooks";

const { Text } = Typography

const SignInPage = (props) => {
  const { 
    className
  } = props || {}

  const [ isLoading, setIsLoading ] = useState(false)
  const [ currentStage, setCurrentStage ] = useState("login") // login, forgot-password, otp, change-password
  const [ timeLeft, setTimeLeft ] = useState(null)
  const [ loggedIn, setLoggedIn ] = useState(false)
  const otp = useRef(null)

  const [ form ] = Form.useForm()
  const { message } = App.useApp()
  const { t } = useTranslation();
  const { isMobile } = useCustomMediaQuery();

  const dispatch = useDispatch()

  const { 
    login, 
    resetPassword, 
    otpValidation, 
    changePassword 
  } = useAuthApi()

  const onSubmit = (inputs) => {
    if (currentStage === "login") {
      doLogin(inputs)
    } else if (currentStage === "forgot-password") {
      doResetPassword(inputs)
    } else if (currentStage === "change-password") {
      doChangePassword(inputs)
    }
  }

  const doLogin = (inputs) => {
    setIsLoading(true)

    login(inputs)
    .then(res => {
      if (res.status !== "ok") return

      const token = res.result.token

      setLoggedIn(true)

      setTimeout(() => {
        dispatch(authActions.setToken(token))
      }, 3000); // will redirect shortly

      message.open({ 
        type: "success",
        content: t("message.login_successfully"),
      })
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const emailFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.email")}
        name="email"
        rules={[...requiredRules(), ...emailRules()]}
        hasFeedback
      >
        <Input 
          autoFocus
          placeholder={t("sign_in.enter_email")} 
          prefix={
            <SvgIcon
              height={isMobile ? "20" : "24"}
              src="email-placeholder-prefix"
            />
          }
        />
      </Form.Item>
    )
  }

  const passwordFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.password")}
        name="password"
        rules={[...requiredRules(), ...passwordRules()]}
        hasFeedback
      >
        <Input.Password 
          placeholder={t("sign_in.enter_password")}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          prefix={
            <SvgIcon
              height={isMobile ? "20" : "24"}
              src="password-placeholder-prefix"
            />
          }
        />
      </Form.Item>
    )
  }

  const confirmPasswordFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.confirm_password")}
        name="confirmPassword"
        rules={[...requiredRules(), ...confirmPasswordRules()]}
        dependencies={['password']}
        hasFeedback
      >
        <Input.Password 
          placeholder={t("sign_in.enter_password")}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          prefix={
            <SvgIcon
              height={isMobile ? "20" : "24"}
              src="password-placeholder-prefix"
            />
          }
        />
      </Form.Item>
    )
  }

  const otpFormItem = () => {
    return (
      <Space 
        direction="vertical" 
        align="center"
        className="w-100"
      >
        <Text 
          type="secondary"
        >
          {t("sign_in.otp_message")}
        </Text>
        
        <Otp
          timeLeft={timeLeft}
          onOtpComplete={doValidateOtp}
          onResendCode={doResetPassword}
        />
        
        <Text 
          type="secondary"
        >
          {t("sign_in.otp_exp_message")}
        </Text>
      </Space>
    )
  }

  const actionFormItems = () => {
    const submit = (
      <Button 
        className="custom-button primary w-100"
        htmlType="submit"
      >
        { currentStage === "login" && t("button.log_in") }
        { currentStage === "forgot-password" && t("button.send") }
        { currentStage === "change-password" && t("button.submit") }
      </Button>
    )
    const forgotPassword = (
      <Button 
        className="custom-button text-pattern w-100"
        onClick={() => setCurrentStage("forgot-password")}
      >
        {t("sign_in.forgot_password")}
      </Button>
    )

    const back = (
      <Button 
        block
        type="text"
        size="large"
        onClick={() => setCurrentStage("login")}
      >
        {t("button.back")}
      </Button>
    )

    const cancel = (
      <Button 
        block
        type="text"
        size="large"
        onClick={() => setCurrentStage("forgot-password")}
      >
        {t("button.cancel")}
      </Button>
    )

    return (
      <Form.Item className="submit-section">

      { currentStage === 'login' && (
        <Space
          direction="vertical"
          className="w-100 h-100"
        >
          { submit }
          { forgotPassword }
        </Space>
      )}

      { currentStage === 'forgot-password' && (
        <Row
          justify="space-between"
        >
          <Col span={11}>
            { back }
          </Col>
          <Col span={11}>
            { submit }
          </Col>
        </Row>
      )}

      { currentStage === 'otp' && (
        <Space
          direction="vertical"
          className="w-100 h-100"
        >
          { cancel }
        </Space>
      )}

      { currentStage === 'change-password' && (
        <Space
          direction="vertical"
          className="w-100 h-100"
        >
          { submit }
          { cancel }
        </Space>
      )}

      </Form.Item>
    )
  }

  const doResetPassword = (inputs) => {
    let email = inputs?.email || form.getFieldValue("email")

    setIsLoading(true)
    setTimeLeft(180)

    resetPassword({ email })
    .then(res => {
      setTimeLeft(res.timeLeft)
      if (res.status !== "ok" && !res.timeLeft) return

      setCurrentStage("otp")
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const doValidateOtp = (code) => {
    otp.current = code
    otpValidation({
      code,
      user: {
        email: form.getFieldValue("email")
      }
    })
    .then(res => {
      if (res.status !== "ok") {
        return
      }

      setCurrentStage("change-password")
    })
  }

  const doChangePassword = (inputs) => {
    let email = form.getFieldValue("email")

    setIsLoading(true)

    changePassword({
      newPassword: inputs.password,
      otp: otp.current,
      email
    })
    .then(res => {
      if (res.status !== "ok") return

      message.open({ 
        type: "success",
        content: t("sign_in.change_password_successfully"),
      })

      form.resetFields()
      setCurrentStage("login")
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Row
      align="middle"
      justify="center"
      className={className + (isMobile ? " mobile-main-row" : " main-row")}
    >
      <Col
        span={isMobile ? 24 : 12}
        className="left-panel-block"
      >
        <h1
          className="title"
        >
          {t("sign_in.title")}
        </h1> 
        <p
          className="subtitle"
        >
          {t("sign_in.subtitle")}
        </p>
        {!isMobile &&
          <Image
            preview={false}
            src={SignInLeftImage}
          />
        }
      </Col>
      <Col
        span={isMobile ? 24 : 12}
        className="right-panel-block"
      >
        <Card
          className="login-card"
          bordered={false}
        >
          <Space
            direction="vertical"
            className="w-100"
          >
            <Row
              align="middle"
              justify="center"
            >
              <SvgIcon
                className="logo-icon"
                height={isMobile ? "58.96" : "77"}
                width={isMobile ? "58.96" : "77"}
                src="logo"
              />
            </Row>
            <Row
              align="middle"
              justify="center"
              className="logo-text-image-row"
            >
              <img
                alt="logo-text"
                width={isMobile ? "75" : "120"}
                src={LogoTextImage}
              />
            </Row>

            {loggedIn && (
              <Button 
                className="custom-button primary w-100"
                loading
              >
                {t("sign_in.logging_you_in")}
              </Button>
            ) }

            {!loggedIn && (
              <Spin
                tip={t("sign_in.loading")}
                size="large"
                indicator={
                  <LoadingOutlined spin />
                }
                spinning={isLoading}
              >
                <Form
                  form={form}
                  name="login"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={onSubmit}
                  preserve={true}
                >
                  { currentStage !== "otp" && currentStage !== "change-password" && emailFormItem() }

                  { (currentStage === "login" || currentStage === "change-password") && passwordFormItem() }

                  { currentStage === "otp" && otpFormItem() }
                  
                  { currentStage === "change-password" && confirmPasswordFormItem() }

                  { actionFormItems() }
                </Form>
              </Spin>
            )}

          </Space>
        </Card>
      </Col>
      {isMobile &&
        <Col 
          span={24}
          className="img-panel-block"
        >
          <Image
            preview={false}
            src={SignInLeftImage}
          />
        </Col>
      }
    </Row>
  )
}

let StyledComponent = styled(SignInPage)`
  ${FormStyle}

  background-color: #F5FDFC;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  &.main-row {
    padding: 5rem;

    .left-panel-block,
    .right-panel-block {
      padding: 1rem 4rem;
    }

    .left-panel-block {
      .ant-image {
        margin-top: 1rem;
      }

      >*:not(.ant-image) {
        max-width: 90%;
      }

      .title {
        font-family: var(--main-font-bold);
        font-size: 36px;
        line-height: 47px;
      }

      .subtitle {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .logo-text-image-row {
      margin-bottom: 2rem;
    }

    .login-card {
      >.ant-card-body {
        padding: 3rem 4rem;
        border-radius: 10px;
      }
    }

    .logo-icon {
      margin-bottom: 1rem;
    }

    .submit-section {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }

  &.mobile-main-row {
    padding: 5rem 1.75rem 0.5rem;

    .left-panel-block, 
    .right-panel-block, 
    .img-panel-block {
      padding-bottom: 1.5rem;
    }

    .right-panel-block, 
    .img-panel-block {
      padding-top: 0;
    }

    .img-panel-block {
      display: flex;
      justify-content: center;
    }

    .left-panel-block {
      .title {
        font-family: var(--main-font-bold);
        font-size: var(--font-size-m);
        margin-bottom: 0.625rem;
      }

      .subtitle {
        font-size: var(--font-size-s);
      }
    }

    .logo-text-image-row {
      margin-bottom: 0;
    }

    .ant-space-gap-col-small {
      column-gap: 0.41rem;
    }

    .ant-form-item {
      :nth-child(1) {
        padding-top: 1.09rem;
      }
    }

    .login-card {
      >.ant-card-body {
        padding: 1.5rem 1.25rem;
        border-radius: 10px;
      }
    }

    .logo-icon {
      margin-bottom: 0;
    }

    .submit-section {
      padding-top: 0.5rem;
      margin-bottom: 0;
    }

    .ant-btn {
      border-radius: 18px;
      height: 40px;
      font-size: var(--font-size-s);
      padding: 0;
    }

    .ant-typography {
      font-size: var(--font-size-s);
    }

    .otp-field {
      margin: 1.09rem 0 1.5rem;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    min-height: 48px;
    border: none;

    &,
    input {
      caret-color: var(--primary-color);
    }

    input::placeholder,
    &::placeholder {
      color: var(--secondary-color);
      font-size: 16px;
      line-height: 32px;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input {
    background: #FAFAFA;
  }

  .login-card .ant-card-body {
    background-color: #FDFFFF;
  }
`


export default StyledComponent