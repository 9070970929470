
import styled from "styled-components"
import { useDispatch } from 'react-redux'
import { authActions } from "@/store/slices/auth"
import { Button, Flex, Row, Tag, Image } from 'antd'
import CustomModal from "@/components/modal"
import { useTranslation } from "react-i18next";

const tagList = [
  "birthdays",
  "leave_request",
  "leave_approval",
  "reject_leave",
  "employees_activity_log",
  "attendance",
  "claim_request",
  "ms_winkly",
]

const logoutImage = require("@/assets/images/logout-image.png")

const Component = (props) => {
  const {
    className,
    open,
    onCancel,
  } = props || {}

  const dispatch = useDispatch()
  const { t } = useTranslation();
  const onConfirmedLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    // NOTE: ok and cancel button are flipped.
    <CustomModal 
      className={className}
      title={
        <h3 className="header">
          {t("logout.heading_out")}
        </h3>
      }
      open={open}
      centered
      footer={null}
      withTitleDivider
      onCancel={onCancel}
    >
      <p className="desc">
        {t("logout.log_out_confirmation")}
      </p>

      <Row
        type="flex"
        justify="start"
        align="middle"  
      >
        {tagList.map((el, index) => (
          <Tag key={index}>
            {t(`logout.${el}`)}
          </Tag>
        ))}
      </Row>

      <Row
        type="flex"
        justify="center"
        align="middle"  
      >
        <Image
          className="logout-image"
          width={306}
          src={logoutImage}
          preview={false}
        />
      </Row>

      <Flex
        type="flex"
        justify="space-around"
        align="center"
        gap="2rem"
      >
        <Button
          className="custom-button secondary w-100"
          onClick={onConfirmedLogout}
        >
          {t("logout.confirm_logout")}
        </Button>
        <Button
          className="custom-button primary w-100"
          onClick={onCancel}
        >
          {t("logout.reject_logout")}
        </Button>
      </Flex>
    </CustomModal>
  )
}

const StyledComponent = styled(Component)`

  .header {
    font-size: var(--font-size-l);
    font-family: var(--main-font-semi-bold);
    text-align: center;
  }

  .desc {
    font-size: var(--font-size-m);
    margin: 2rem 0;
  }

  .ant-tag {
    border: 1px solid #EBF1FD;
    border-radius: 20px;
    color: #009FB2;
    background-color: white;
    font-size: var(--font-size-s);
    padding: 0.25rem 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .logout-image {
    margin: 2.5rem 0;
  }

`

export default StyledComponent