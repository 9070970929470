import { Spin } from "antd"
import { Suspense, lazy } from "react"
import styled from "styled-components"

const LazyLoadWrapper = (props) => {
  const {
    className,
    importFn,
    children,
  } = props || {}

  const ReactChild = lazy(importFn)

  const fallback = (
    <Spin 
      className={className + " page-spinner"}
      spinning
    />
  )

  return (
    <Suspense fallback={fallback}>
      <ReactChild>
        {children}
      </ReactChild>
    </Suspense>
  )
}

const StyledComponent = styled(LazyLoadWrapper)`
&.page-spinner {
  width: 100%;
  height: calc(100% - 8rem);

  .ant-spin-dot.ant-spin-dot-spin {
    position: fixed;
    top: 50%;
  }
}
`

export default StyledComponent