import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { DEFAULT_LANG } from "@/constants"
import isEqual from "lodash/isEqual";

export const useClickOutsideAlerter = (refList, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      let haveClickedSelf = false

      refList.forEach((ref) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
        } else {
          haveClickedSelf = true
        }
      })

      if (!haveClickedSelf) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refList]);
}

export const useCustomMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleViewportChange = () => {
    // Access window and perform client-side operations
    if (typeof window !== 'undefined') {
      // Get the computed style of the root element
      const rootStyles = window.getComputedStyle(document.documentElement);
      const MOBILE_MAX_WIDTH_PX = parseInt(rootStyles.getPropertyValue('--mobile-max-width'));
      const viewportWidth = window.innerWidth;
      
      setIsMobile(viewportWidth <= MOBILE_MAX_WIDTH_PX)
    }
  }

  useEffect(() => {
    handleViewportChange() // initial call once
    window.addEventListener('resize', handleViewportChange);
    return () => {
      window.removeEventListener('resize', handleViewportChange)
    }
  }, [])

  return { isMobile }
}

export const useIsRendered = () => {
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  return { isRendered }
}

export const useBETranslation = () => {
  const { i18n } = useTranslation('home');

  const getTranslation = (translationListOrObj, emptyText = "", onlyLanguage = null) => {
    const locale = i18n.language
    if (!translationListOrObj) return emptyText
    const type = typeof translationListOrObj
    if (type === 'string' || type === 'number') return translationListOrObj
  
    let translationList = translationListOrObj
    // the object passed in is not the list, get it
    if (!translationListOrObj.length) translationList = translationListOrObj.translationList
    if (!translationList) return emptyText
  
    let langObjFound = translationList.find(trans => trans.language?.code === (onlyLanguage || locale))
    if (onlyLanguage === null && (langObjFound === null || langObjFound === undefined || langObjFound.name === "" || !langObjFound.name)) { // change to default ONLY if onlyLanguage is not set
      langObjFound = translationList.find(trans => trans.language?.code === DEFAULT_LANG)
      if (langObjFound === null) {
        langObjFound = translationList[0] // default not available -> get the first element
      }
    }
    return langObjFound ? langObjFound.name : emptyText
  }
  
  return getTranslation
}

export const useDeepCompareMemoize = (value) => {
  const ref = useRef() 

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize)
  )
}

export const useLanguageChange = () => {
  const { i18n } = useTranslation();
  const [languageKey, setLanguageKey] = useState(0); // State to trigger remounting when language changes

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguageKey(prevKey => prevKey + 1);
    };

    // Subscribe to language change event
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      // Unsubscribe from language change event
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return languageKey;
};

export const useScrollToHash = () => {
  useEffect(() => {
    const hash = window.location.hash;
    const targetId = hash.substring(1);
    const targetElement = document.getElementById(targetId);

    if (!!targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);
};
