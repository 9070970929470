import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import values from "lodash/values"

import PublicLayout from "@/layouts/public"
import ErrorPage from "@/pages/error-page.js"

// you do not need `import app from './modules/app'`
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

let modulesItems = values(modules)

modulesItems.push({
  path: "*",
  element: (
    <PublicLayout>
      <ErrorPage errorType="404"/>
    </PublicLayout>
  )
})

const router = createBrowserRouter(modulesItems);

const Router = () => (
  <RouterProvider 
    router={router} 
  />
)

export default Router

export const allRoutes = modulesItems