import { ThemeProvider } from "styled-components"
import { useState, useEffect } from "react"

const Component = (props) => {
  const {
    children
  } = props || {}

  const [maxWidth, setMaxWidth] = useState()

  useEffect(() => {
    // Access window and perform client-side operations
    if (typeof window !== 'undefined') {
      // Get the computed style of the root element
      const rootStyles = window.getComputedStyle(document.documentElement);
      const MOBILE_MAX_WIDTH_PX = rootStyles.getPropertyValue('--mobile-max-width');
      setMaxWidth(MOBILE_MAX_WIDTH_PX)
    }
  }, [])

  return (
    <ThemeProvider
      theme={{
        maxWidth
      }}
    >
      {children}
    </ThemeProvider>
  )
}

export default Component