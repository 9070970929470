import styled from "styled-components";
import SvgIcon from "@/components/svg-icon";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const ErrorPage = (props) => {
  const { 
    className, 
    errorType 
  } = props || {};

  const { t } = useTranslation();
  const svgWidth = errorType === "404" ? 400 : 350;
  const svgHeight = errorType === "404" ? 200 : 350;

  return (
    <div className={className}>
      <div className="frame">
        <div className="content">
          <div className="info">
            <SvgIcon
              src={`error-page-${errorType}`}
              width={svgWidth}
              height={svgHeight}
              className="svg-icon"
            />
            <p
              className={`text ${
                errorType === "404" ? "error404" : "error500"
              }`}
            >
              {t(`message.${errorType === "404" ? "page_doesnt_exist" : "try_again_message"}`)}
            </p>
          </div>
          <a href="/">
            <Button className="custom-button primary">
              {t("button.back_to_home")}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

let StyledComponent = styled(ErrorPage)`
  display: flex;
  justify-content: center;
  padding-top: 5rem;

  .frame {
    background-color: var(--primary-text-color);
    border-radius: 6px;
    padding: 1.5rem 2rem;
    width: 80%;
    height: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 100%;
    max-width: 400px;

    a {
      width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .svg-icon {
    position: relative;
    margin: 0 !important;
  }

  .text {
    font-size: var(--font-size-l);
    text-align: center;

    &.error404 {
      margin-top: 3rem;
    }

    &.error500 {
      margin-top: -5rem;
    }
  }

  .custom-button {
    &.primary {
      width: 100%;
      font-size: var(--font-size-l);
      padding: 1.5rem;
    }
  }
`;

export default StyledComponent;
