import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LANG } from "@/constants";
import ls from 'localstorage-slim';

const initialState = { 
  locale: ls.get("locale") || DEFAULT_LANG,
  allClaimType: [],
}

const claimSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAllClaimType(state, action) {
      state.allClaimType = action.payload
    },
  },
});

const allActions = claimSlice.actions

export const claimActions = {
  ...allActions,
}

export default claimSlice.reducer;