import { createSlice } from '@reduxjs/toolkit'
import ls from 'localstorage-slim';
import { appActions } from "@/store/slices/app";

const initialState = { 
  token: ls.get("token", { decrypt: true }) || null,
  profileObj: {},
  companyTimezone: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      const token = action.payload
      state.token = token

      if (!token) {
        ls.remove('token')
      } else {
        ls.set('token', token, { decrypt: true })
      }
    },                                                                                                                                         
    setProfileObj(state, action) {
      state.profileObj = action.payload
    },
    setCompanyTimezone(state, action) {
      state.companyTimezone = action.payload
    },
  },
});

const allActions = authSlice.actions

export const authActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  logout: () => {
    return async (dispatch) => {
      dispatch(allActions.setToken(null));
      dispatch(appActions.setAllDepartmentList([]));
      dispatch(appActions.setAllJobTitleList([]));
    }
  }
}

export const authSelectors = {
  selectIsLoggedIn: (state) => !!state.auth.token,
  selectIsAdmin: (state) => state.auth.profileObj?.userRole?.role?.name === "COMPANY_ADMIN",
  selectIsHR: (state) => state.auth.profileObj?.userRole?.role?.name === "HR",
  selectIsApprover: (state) => state.auth.profileObj?.userRole?.role?.name === "APPROVER",
}

export default authSlice.reducer;