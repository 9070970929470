import { createSlice } from '@reduxjs/toolkit'
import {i18next} from '@/locale';
import { DEFAULT_LANG } from "@/constants";
import ls from 'localstorage-slim';

const initialState = { 
  locale: ls.get("locale") || DEFAULT_LANG,
  currentRouteObj: {},

  isCurrentTabActive: false,

  allDepartmentList: [],
  allJobTitleList: [],

  isAssignModalDisplayList: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload
    },
    setCurrentRouteObj(state, action) {
      state.currentRouteObj = action.payload
    },
    setIsCurrentTabActive(state, action) {
      state.isCurrentTabActive = action.payload
    },
    setAllDepartmentList(state, action) {
      state.allDepartmentList = action.payload
    },
    setAllJobTitleList(state, action) {
      state.allJobTitleList = action.payload
    },
    setIsAssignModalDisplayList(state, action) {
      state.isAssignModalDisplayList = action.payload
    },
  },
});

const allActions = appSlice.actions

export const appActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  setLocale: (value) => {
    return async (dispatch) => {
      dispatch(allActions.setLocale(value))
      i18next.changeLanguage(value)      
      ls.set("locale",value)
    }
  }
}

export default appSlice.reducer;