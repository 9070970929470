import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const moduleTitle = "module_title.help";

const index = {
  path: "help",
  title: moduleTitle,
  sidebarProps: {
    key: "2-2",
    logoSrc: "help",
    label: moduleTitle,
    link: "/help",
    groupingType: "OTHERS",
    order: 2,
  },
  element: (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/help")} 
        />
      </RouteGuard>
    </BOLayout>
  )
}

export default index