import userOnboardingLoader from "@/pages/public/user-onboarding/loader"
import PublicLayout from "@/layouts/public"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const RouteGuardWrap = (importFn) => {
  return (
    <PublicLayout>
      <RouteGuard>
        <LazyLoadWrapper 
          importFn={importFn} 
        />
      </RouteGuard>
    </PublicLayout>
  );
};

const onboarding = {
  path: "/public",
  children: [
    {
      path: "company-onboarding",
      element: RouteGuardWrap(() => import("@/pages/public/company-onboarding")),
    },
    {
      path: "user-onboarding/:token",
      element: RouteGuardWrap(() => import("@/pages/public/user-onboarding")),
      loader: userOnboardingLoader,
    },
    {
      path: "privacy-policy",
      element: RouteGuardWrap(() => import("@/pages/public/privacy-policy"))
    }
  ]
}

export default onboarding