import { useApi } from '@/utils/request'

export const useUtilityApi = () => {
  const [api] = useApi()

  const getCompanySize = () => {
    return api({
      url: '/utility/company-size',
      method: "get",
    })
  }

  const getStateList = () => {
    return api({
      url: '/utility/state/list',
      method: "get",
    })
  }

  const upload = (data) => {
    return api({
      url: '/upload/create',
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data
    })
  }

  const getAllDepartment = (data) => {
    return api({
      url: '/department/list',
      method: "post",
      data
    })
  }

  const addDepartment = (data) => {
    return api({
      url: '/department/create',
      method: "post",
      data
    })
  }

  const editDepartment = (data) => {
    return api({
      url: '/department/update',
      method: "post",
      data
    })
  }

  const getAllJobTitle = (data) => {
    return api({
      url: '/job/list',
      method: "post",
      data
    })
  }

  const addJobTitle = (data) => {
    return api({
      url: '/job/create',
      method: "post",
      data
    })
  }

  const editJobTitle = (data) => {
    return api({
      url: '/job/update',
      method: "post",
      data
    })
  }

  const bulkGetGenericRecordList = (data) => {
    return api({
      url: "/generic-record/bulk-get-by-type",
      method: "post",
      data
    })
  }

  return {
    getCompanySize,
    getStateList,
    upload,
    getAllDepartment,
    addDepartment,
    editDepartment,
    getAllJobTitle,
    addJobTitle,
    editJobTitle,
    bulkGetGenericRecordList
  }
  
}
