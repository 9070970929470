import { Modal, Divider, Spin } from "antd"
import styled from "styled-components"
import SvgIcon from "@/components/svg-icon"
import { forwardRef } from "react"

const CustomModal = forwardRef((props, ref) => {
  const {
    className,
    children,
    withTitleDivider,
    isLoading = false,
    width,
  } = props || {}

  const getClassName = () => {
    let resultList = [className]

    resultList.push("custom-modal")

    return resultList.join(" ")
  }

  return (
    <Modal
      {...props}
      destroyOnClose={props.destroyOnClose || true}
      className={getClassName()}
      closeIcon={!isLoading && (
        <SvgIcon
          src="modal-close-button"
          width="24"
          height="24"
          className="center"
        />
      )}
      maskClosable={props.maskClosable && !isLoading}
      keyboard={props.keyboard && !isLoading}
      centered      
      width={width}
    >
      <Spin spinning={isLoading}>
        {!!withTitleDivider && (
          <Divider
            className="header-content-divider expanded-divider"
          />
        )}
      
        <div ref={ref}>
          {children}
        </div>

      </Spin>
    </Modal>
  )
})

const StyledComponent = styled(CustomModal)`
overflow-x: hidden;

.header-content-divider {
  margin-bottom: 0;
  margin: 1rem 0;
}

.ant-modal-content {
  padding: 1.5rem;
  .ant-modal-close {
    top: 1.5rem;
    inset-inline-end: 1.5rem;
    &:hover {
      background: transparent;
    }
  }
}

.ant-modal-title {
  font: normal normal normal var(--font-size-l) var(--main-font-semi-bold);
  margin: 0;
}

`

export default StyledComponent