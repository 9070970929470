import { redirect } from "react-router-dom";

const loader = async (req) => {
  const token = req?.params?.token
  
  if (!token) return redirect("/404")

  return { token }
}

export default loader