export const API_URL = process.env.REACT_APP_API_URL;

// Ant Design UI
export const NOTIFY_POSITION = "bottomRight";
export const NOTIFY_DURATION = 5; // seconds

// Code for identification
export const DEFAULT_TIMEOUT = 120000; // 2mins
export const EXTENDED_TIMEOUT = 1200000; // 20mins
export const NO_TIMEOUT = 0;

// === OTHERS ===
export const DEFAULT_LANG = "en";
export const LANGUAGE_OPTION = [
  {
    value: "en", 
    label: "general.lang_en"
  },
  {
    value: "zh", 
    label: "general.lang_zh"
  }
];

export const ALL_FILE_TYPE = {
  IMAGE: ".tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png",
  EXCEL: ".csv, .xlsx, .xls",
  DOC: ".doc, .docx, .pdf",
};

export const PAGE_SIZE = [10, 20, 50, 100, 200];
export const TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "DD-MM-YYYY";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const EVENT_TYPE = ["HOLIDAY", "EVENT"];

export const ACTIVE_STATUS = [
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
];

export const APPROVAL_STATUS = [
  {
    value: "PENDING",
    label: "status.pending",
    disabled: true,
  },
  {
    value: "APPROVED",
    label: "status.approved",
    disabled: false,
  },
  {
    value: "REJECTED",
    label: "status.rejected",
  },
];

export const ASSET_APPROVAL_STATUS = [
  {
    value: "PENDING",
    label: "status.pending",
  },
  {
    value: "ACCEPTED",
    label: "status.accepted",
  },
  {
    value: "REJECTED",
    label: "status.rejected",
  },
  {
    value: "RETURNED",
    label: "status.returned",
  },
];

export const ASSET_USER_STATUS = [
  {
    value: "PENDING",
    label: "status.pending",
  },
  {
    value: "ACCEPTED",
    label: "status.accepted",
  },
  {
    value: "REJECTED",
    label: "status.rejected",
  },
  {
    value: "RETURNED",
    label: "status.returned",
  },
  {
    value: null,
    label: "status.all",
  },
];

export const EMPLOYEE_REQUIRE_ENCRYPTION_FIELD_NAME_LIST = [
  "bankAccNo",
  "epfNo",
  "socsoNo",
  "basicSalary",
  "incomeTaxNo",
  "policyNo",

  // For excel sheet data 
  "Bank Account Number",  
  "EPF Number",
  "Socso Number",
  "Basic Salary",
  "Income Tax Number",
  "Insurance Policy Number"
];

// DO NOT CHANGE THE SEQUENCE
export const ACTIVITY_LIST = [
  {
    type: "HOLIDAY",
    svg: "holiday",
    class: "holiday",
    calendarObjName: null,  // share obj{} with event
    calendarNameObj: null,  
    calendarAvatarObj: null,  
  },
  {
    type: "EVENT",
    svg: "event",
    class: "event",
    calendarObjName: "event",
    calendarNameObj: "name",
    calendarAvatarObj: null,
  },
  {
    type: "REPLACEMENT",
    svg: "replacement",
    class: "replacement",
    calendarObjName: null, // share obj{} with event
    calendarNameObj: null,
    calendarAvatarObj: null,
  },
  {
    type: "BIRTHDAY",
    svg: "birthday",
    class: "birthday",
    calendarObjName: "user",
    calendarNameObj: "displayName",
    calendarAvatarObj: "profilePicUrl",
  },
  {
    type: "LEAVE",
    svg: 'leave',
    class: 'leave',
    calendarObjName: "leave",
    calendarNameObj: "user.displayName",
    calendarAvatarObj: "user.profilePicUrl",
  },
  {
    type: "TASK",
    svg: "task-bag",
    class: "task",
    calendarObjName: "task",
    calendarNameObj: "user.displayName",
    calendarAvatarObj: "user.profilePicUrl"
  },
  {
    type: "CLAIM",
    svg: "currency-outline",
    class: null,
    calendarObjName: null,  // share obj{} with event
    calendarNameObj: null,  
    calendarAvatarObj: null,  
  },
]

export const CURRENCY = "RM"

export const WORKING_DAY_IN_ORDER = [
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
  "SUN",
]

export const ALL_COMPANY_SIZES = [
  { label: "company_size.small", value: "SMALL" },
  { label: "company_size.medium", value: "MEDIUM" },
  { label: "company_size.semi_large", value: "SEMI_LARGE" },
  { label: "company_size.large", value: "LARGE" },
]

export const LEAVE_ACTION_TYPE = [
  { key: 1, label: "leave.carry_forward" },
  { key: 2, label: "leave.carry_forward_edit" },
  { key: 3, label: "leave.group_change" },
  { key: 4, label: "leave.group_remove" },
  { key: 5, label: "leave.initial_entitled_reset" },
  { key: 6, label: "leave.leave_adjustment" },
  { key: 7, label: "leave.leave_approved" },
  { key: 8, label: "leave.leave_balance_created" },
  { key: 9, label: "leave.leave_cancel" },
  { key: 10, label: "leave.leave_edit" },
  { key: 11, label: "leave.leave_replacement_with_expiration" },
]

export const CHECK_NOTIFICATION_INTERVAL_IN_MS = 5000

export const ALL_GENDER = [
  { label: "employee.male", value: "M" },
  { label: "employee.female", value: "F" },
]

export const ALL_MONTH = [
  { key: "null", label: "month.this_year" },
  { key: "1", label: "month.january" },
  { key: "2", label: "month.february" },
  { key: "3", label: "month.march" },
  { key: "4", label: "month.april" },
  { key: "5", label: "month.may" },
  { key: "6", label: "month.june" },
  { key: "7", label: "month.july" },
  { key: "8", label: "month.august" },
  { key: "9", label: "month.september" },
  { key: "10", label: "month.october" },
  { key: "11", label: "month.november" },
  { key: "12", label: "month.december" }  
]

export const SORT_DIRECTIONS = [
  "ascend", 
  "descend", 
  "ascend",
]

export const SORT_TEXT = {
  triggerDesc: "general.click_to_sort_descend",
  triggerAsc: "general.click_to_sort_ascend",
  cancelSort: "general.click_to_cancel_sort",
};

export const SERVER_TIMEZONE = process.env.REACT_APP_SERVER_TIMEZONE
