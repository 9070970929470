import BOLayout from "@/layouts/bo";
import RouteGuard from "@/utils/route-guard";
import LazyLoadWrapper from "../lazy-load-wrapper";

const RouteGuardWrap = (importFn) => {
  return (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/settings")}
        >
          <LazyLoadWrapper 
            importFn={importFn} 
          />
        </LazyLoadWrapper>
      </RouteGuard>
    </BOLayout>
  );
};

const moduleTitle = "module_title.settings";

const index = {
  path: "settings",
  title: moduleTitle,
  sidebarProps: {
    key: "2-1",
    logoSrc: "settings",
    label: moduleTitle,
    link: "/settings/user-access-level",
    groupingType: "OTHERS",
    order: 1,
  },
  children: [
    {
      index: true,
      path: "",
      element: RouteGuardWrap(() => import("@/pages/settings/user-access-level")),
    },
    {
      path: "user-access-level",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/user-access-level")),
    },
    {
      path: "leave",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/leave")),
    },
    {
      path: "attendance",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/attendance")),
    },
    {
      path: "claim",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/claim")),
    },
    {
      path: "notification",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/notification")),
    },
    {
      path: "general",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/general")),
    },
    {
      path: "onboarding",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/settings/onboarding")),
    },
  ],
};

export default index;
