import { useApi } from '@/utils/request'

export const useNotificationApi = () => {
  const [api, apiWithoutAxiosError] = useApi()

  const getNotificationList = (data) => {
    return api({
      url: "/notification/list",
      method: "post",
      data
    })
  }

  const readNotification = (id) => {
    return api({
      url: "/notification/read/" + id,
      method: "get",
    })
  }

  const markAllUnreadAsRead = () => {
    return api({
      url: "/notification/mark-all-unread-as-read",
      method: "get",
    })
  }

  const checkNotification = () => {
    return apiWithoutAxiosError({
      url: "/notification/check",
      method: "get",
    })
  }

  return {
    getNotificationList,
    readNotification,
    markAllUnreadAsRead,
    checkNotification,
  }
  
}
