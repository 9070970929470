import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { BE_DATE_FORMAT } from "@/constants";

const today = dayjs().format(BE_DATE_FORMAT);

const initialState = {
  calendarSelectedDate: today,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCalendarSelectedDate(state, action) {
      state.calendarSelectedDate = action.payload;
    },
  }
});

const allActions = dashboardSlice.actions;

export const dashboardActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
};

export default dashboardSlice.reducer;
