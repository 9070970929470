import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const moduleTitle = "module_title.holiday_event";

const index = {
  path: "event",
  title: moduleTitle,
  sidebarProps: {
    key: "1-3",
    logoSrc: "calendar",
    label: moduleTitle,
    link: "/event",
    groupingType: "TOOLS",
    order: 3,
  },
  element: (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/event/list")} 
        />
      </RouteGuard>
    </BOLayout>
  )
}

export default index