import BOLayout from "@/layouts/bo";
import RouteGuard from "@/utils/route-guard";
import LazyLoadWrapper from "../lazy-load-wrapper"

const RouteGuardWrap = (importFn) => {
  return (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/leave-task")}
        >
          <LazyLoadWrapper 
            importFn={importFn}
          />
        </LazyLoadWrapper>
      </RouteGuard>
    </BOLayout>
  );
};

const moduleTitle = "module_title.leave_and_task";

const index = {
  path: "leave-task",
  title: moduleTitle,
  element: null,
  sidebarProps: {
    key: "1-4",
    logoSrc: "stickynote",
    label: moduleTitle,
    link: "/leave-task/summary",
    groupingType: "TOOLS",
    order: 4,
  },
  children: [
    {
      index: true,
      path: "",
      element: RouteGuardWrap(() => import("@/pages/leave-task/summary")),
    },
    {
      path: "summary",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/leave-task/summary")),
    },
    {
      path: "balance",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/leave-task/leave-balance")),
    },
    {
      path: "log",
      title: moduleTitle,
      element: RouteGuardWrap(() => import("@/pages/leave-task/leave-log")),
    },
  ],
};

export default index;
