import { ConfigProvider, App } from "antd"
import {
  NOTIFY_POSITION,
  NOTIFY_DURATION
} from "@/constants"

const antDesignTheme = {
  // GLOBAL token
  token: {
    colorPrimary: "var(--primary-color)",
    fontFamily: "inherit",
    controlOutline: "var(--primary-color)",
    controlOutlineWidth: 1,
    controlItemBgActive: "rgba(0, 0, 0, 0.04)",
  },
  // COMPONENTS token
  components: {
    Select: {
      colorBgContainer: "white",
      colorBorder: "none",
      borderRadius: "10px",
      fontFamily: "var(--main-font-bold)",
    },
    Progress: {
      defaultColor: "var(--primary-color)",
      borderRadius: "6px",
      remainingColor: "#B6B6B64D",
    },
    Segmented: {
      itemSelectedBg: "var(--icon-active)",
    }
  }
}

const AntDesignConfig = (props) => {
  const {
    children
  } = props || {}

  return (
    <ConfigProvider
      theme={antDesignTheme}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  )
}

export const notificationConfig = {
  placement: NOTIFY_POSITION,
  duration: NOTIFY_DURATION,
}


export default AntDesignConfig