import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const index = {
  path: "dashboard",
  title: "module_title.dashboard",
  sidebarProps: {
    key: "1-1",
    logoSrc: "home",
    label: "module_title.home",
    link: "/dashboard",
    groupingType: "TOOLS",
    order: 1,
  },
  element: (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/dashboard")} 
        />
      </RouteGuard>
    </BOLayout>
  )
}

export default index