import i18next from "i18next";
import en from "./en.json"
import zh from "./zh.json"
import { DEFAULT_LANG } from "@/constants";
import ls from 'localstorage-slim';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: ls.get("locale") || DEFAULT_LANG,                      // language to use
  resources: {
    en: {
      translation: en
    },
    zh: {
      translation: zh
    },
  },
  fallbackLng: {
    default: [DEFAULT_LANG],
  }
});

export { i18next };