import { useApi } from "@/utils/request";

export const useCompanyApi = () => {
  const [api] = useApi()

  const getCompanyProfile = () => {
    return api({
      url: '/company/user-company-profile',
      method: "get",
    })
  }

  const updateCompanyProfile = (data) => {
    return api({
      url: '/company/update',
      method: "post",
      data: data
    })
  }

  return {
    getCompanyProfile,
    updateCompanyProfile,
  }
}