import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const RouteGuardWrap = (importFn) => {
  return (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/asset")}
        >
          <LazyLoadWrapper 
            importFn={importFn} 
          />
        </LazyLoadWrapper>
      </RouteGuard>
    </BOLayout>
  );
};

const moduleTitle = "module_title.asset_management";

const index = {
  path: "asset",
  title: moduleTitle,
  sidebarProps: {
    key: "1-8",
    logoSrc: "asset",
    label: moduleTitle,
    link: "/asset/company-asset-list",
    groupingType: "TOOLS",
    order: 8,
  },
  children: [
    {
      index: true,
      path: "",
      element: RouteGuardWrap(() => import("@/pages/asset/company-asset-list")),
    },
    {
      path: "company-asset-list",
      title: moduleTitle,
      children: [
        {
          path: "",
          title: moduleTitle,
          element: RouteGuardWrap(() => import("@/pages/asset/company-asset-list")),
        },
        {
          path: ":id",
          title: "module_title.asset_details",
          headerProps: {
            backUrl: "/asset/company-asset-list"
          },
          element: (
            <BOLayout>
              <RouteGuard mustAuthenticated>
                <LazyLoadWrapper 
                  importFn={() => import("@/pages/asset/company-asset-list/detail")}
                />
              </RouteGuard>
            </BOLayout>
          )
        },
      ],
    },
    {
      path: "employee-asset-list",
      title: moduleTitle,
      children: [
        {
          path: "",
          title: moduleTitle,
          element: RouteGuardWrap(() => import("@/pages/asset/employee-asset-list/list")),
        },
        {
          path: ":id",
          title: moduleTitle,
          headerProps: {
            backUrl: "/asset/employee-asset-list"
          },
          element: (
            <BOLayout>
              <RouteGuard mustAuthenticated>
                <LazyLoadWrapper 
                  importFn={() => import("@/pages/asset/employee-asset-list/detail")}
                />
              </RouteGuard>
            </BOLayout>
          )
        },
      ],
    },
    {
      path: "asset-type",
      title: moduleTitle,
      children: [
        {
          path: "",
          title: moduleTitle,
          element: RouteGuardWrap(() => import("@/pages/asset/asset-type/list")),
        },
      ],
    },
  ],
};


export default index