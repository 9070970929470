import SignIn from "@/pages/sign-in"
import PublicLayout from "@/layouts/public"
import RouteGuard from "@/utils/route-guard"

const signIn = {
  path: "/sign-in",
  element: (
    <PublicLayout>
      <RouteGuard mustNotAuthenticated>
        <SignIn />
      </RouteGuard>
    </PublicLayout>
  )
}

export default signIn