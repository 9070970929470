import BOLayout from "@/layouts/bo";
import RouteGuard from "@/utils/route-guard";
import LazyLoadWrapper from "../lazy-load-wrapper";

const RouteGuardWrap = (importFn) => {
  return (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/claim")}
        >
          <LazyLoadWrapper 
            importFn={importFn} 
          />
        </LazyLoadWrapper>
      </RouteGuard>
    </BOLayout>
  );
};

const moduleTitle = "module_title.claim";

const index = {
  path: "claim",
  title: moduleTitle,
  element: null,
  sidebarProps: {
    key: "1-6",
    logoSrc: "currency",
    label: moduleTitle,
    link: "/claim/list",
    groupingType: "TOOLS",
    order: 6,
  },
  children: [
    {
      index: true,
      path: "",
      element: RouteGuardWrap(() => import("@/pages/claim/list")),
    },
    {
      path: "list",
      title: moduleTitle,
      children: [
        {
          path: "",
          title: moduleTitle,
          element: RouteGuardWrap(() => import("@/pages/claim/list")),
        },
        {
          path: ":id",
          title: "module_title.claim_details",
          headerProps: {
            backUrl: "/claim/list",
          },
          element: (
            <BOLayout>
              <RouteGuard mustAuthenticated>
                <LazyLoadWrapper
                  importFn={() => import("@/pages/claim/details")}
                />
              </RouteGuard>
            </BOLayout>
          ),
        },
      ],
    },
    {
      path: "summary",
      title: moduleTitle,
      children: [
        {
          path: "",
          title: moduleTitle,
          element: RouteGuardWrap(() => import("@/pages/claim/summary")),
        },
      ],
    },
  ],
};

export default index;
