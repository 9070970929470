import BOLayout from "@/layouts/bo"
import RouteGuard from "@/utils/route-guard"
import LazyLoadWrapper from "../lazy-load-wrapper"

const index = {
  path: "notification",
  title: "module_title.notification",
  element: (
    <BOLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper 
          importFn={() => import("@/pages/notification")} 
        />
      </RouteGuard>
    </BOLayout>
  )
}

export default index