import { useCustomMediaQuery } from "@/hooks"

const PublicLayout = (props) => {
  const {
    children
  } = props || {}

  const { isMobile } = useCustomMediaQuery()

  return (
    <div className={isMobile ? 'mobile' : ''}>
      {children}
    </div>
  )
}

export default PublicLayout